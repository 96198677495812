
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        













































































.net-cpl__centered-block {
  background-color: $c-white-dark;
}
.net-cpl-table {
  ::v-deep {
    .table__layout {
      grid-template-rows: 15rem 1fr 5rem;
      max-width: 152rem;
    }

    .table-content__data__spec {
      width: 32%;
    }

    .table-content__data__value {
      width: 33%;
    }

    .table-content__header__block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 34%;
      height: auto;
      padding-top: $spacing;
      padding-bottom: $spacing;

      h4 {
        margin-bottom: $spacing * 0.5;
      }
    }

    .table-content__header__picture {
      position: relative;
      display: block;
      width: 10rem;
      height: 10rem;

      img {
        @include image-fit;

        object-fit: contain;
      }
    }

    .table-content__data-outer {
      @include mq(l) {
        padding-bottom: $spacing * 3;
      }
    }

    .price-box {
      width: auto;
      background-color: none;
      box-shadow: none;
    }

    .price-box__price > small :last-child {
      display: none;
    }
  }
}

.net-cpl__table {
  position: relative;
}
